
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions, JobDescriptions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { object } from "yup";
import { parseVeeErrors } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = object({
      name: Yup.string().required().label("Company Name"),
      accountOwner: object({
        username: Yup.string().required().label("Username"),
        email: Yup.string().min(4).required().email().label("Email"),
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        phoneNumber: Yup.string().required().label("Phone Number"),
        jobDescription: Yup.number().required().label("Job Description"),
        newPassword: Yup.string().required().label("Password"),
        passwordConfirmation: Yup.string()
          .required()
          .oneOf([Yup.ref("newPassword")], "Passwords must match")
          .label("Password Confirmation"),
      }),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    const onSubmitRegister = async (values, actions) => {
      await store.dispatch(Actions.LOGOUT);
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      values.accountOwner.jobDescription = parseInt(
        values.accountOwner.jobDescription
      );

      let success = false;
      let response = {};
      try {
        response = await ApiService.post("api/v1/accounts/register", values);
        success = true;
      } catch (e) {
        actions.setErrors(parseVeeErrors(e.response));
        success = false;
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      submitButton.value!.disabled = false;

      if (success) {
        let payload = {
          username: values.accountOwner.username,
          password: values.accountOwner.newPassword,
        };
        await store.dispatch(Actions.LOGIN, payload);

        Swal.fire({
          text: "You have successfully created new account!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.push({ name: "account-plan" });
        });
      } else {
        Swal.fire({
          text: "Error registering!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      JobDescriptions,
    };
  },
});
